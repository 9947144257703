<template>
  <div class="content login">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>登录</p>
    </div>

    <div class="login_box flex">
      <img class="img_login" src="../assets/images/img_login.png" />
      <div class="login_form">
        <h1>登录</h1>
        <input class="input_text" type="text" v-model="username" placeholder="请输入用户名"/>
        <input class="input_text" type="password" v-model="password" placeholder="请输入密码"/>
        <div class="code flex">
          <input class="input_text" type="text" v-model="code" placeholder="请输入验证码">
          <div id="checkCode" @click="createCode()"><p>{{ verify_code }}</p></div>
        </div>
        
        <input class="input_submit" type="submit" value="登录" @click="login()"/>
        <div class="others flex">
          <button class="btn_reg" @click="regiter">立即注册</button>
          <button class="btn_reg" @click="forget">忘记密码</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'LoginView',
  components: {
    Footer
  },
  data() {
      return {
        username: '',
        password: '',
        code: '',
        verify_code: '',
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.createCode();   
  },

  // 方法
  methods: {
    //登录
    login(){
      if(!this.username || !this.password){
        this.$dialog('账号密码不能为空');
        return false
      }

      console.log(this.code.toUpperCase())
      if(this.code.toUpperCase() != this.verify_code){
        this.$dialog('验证码不正确');
        return false
      }
      
      this.axios.post(
        '/api/user/login', {
          account: this.username,
          password: this.password,
          code: this.code,
        }
      ).then((res)=>{
        var that = this
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.setItem('token', res.data.data.userinfo.token)
          setTimeout(function(){
            that.$router.push({path:'/index'})
          }, 1000)
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //生成验证码
    createCode() {
      var code = '';
      var codeLength = 4; //验证码的长度
      var selectChar = new Array(2, 3, 4, 5, 6, 7, 8, 9, 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z');
      for (var i = 0; i < codeLength; i++) {
          var charIndex = Math.floor(Math.random() * 32);
          code += selectChar[charIndex];
      }
      if (code.length != codeLength) {
          this.createCode();
      }
      this.verify_code = code;
    },

    goback(){
      this.$router.push({path:'/index'})
    },

    regiter(){
      this.$router.push({path:'/register'})
    },

    forget(){
      this.$router.push({path:'/forget'})
    },
  }
        
}
</script>

<style scoped>
  #checkCode{
    width: 40%;
    height: 44px;
    line-height: 44px;
    border-radius: 3px;
    margin-bottom: 20px;
    margin-left: 10px;
    background: #fff;
    cursor: pointer;
  }
  #checkCode p{
    transform: rotate( -5deg );
    color: #3F56B4;
    font-family: 'Impact';
    font-size: 24px;
    letter-spacing: 0.1em;
    font-weight: bold;
    filter: contrast(200%) brightness(150%);
  }
</style>
