<template>
  <div class="content">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>资金明细</p>
      <div class="button btn_pub"></div>
    </div>
    
    <div class="list">
      <div class="item flex" v-for="(item, index) in record">
        <div class="left"><p>{{ item.memo }}（USDT）</p><span>{{ item.createtime }}</span></div>
        <p>{{ item.money }}</p>
      </div>
    </div>

    <div class="no_date" v-if="record.length == 0">暂无数据~</div>

    <Footer activeIndex="0"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'PublistView',
  components: {
    Footer
  },
  data() {
      return {
        record: [],
        page: 1,
        loaded: false,
        loading: false
      };
    },

  created() {
  },

  //模板渲染后调用
  mounted() {
    if(this.checkLogin()){
      this.loadData(this.page)
    }
  },

  // 方法
  methods: {
    handleScroll(event) {
      if(this.loaded) return;//加载完毕
      const { scrollTop, scrollHeight, clientHeight } = event.target;
      const bottomDistance = scrollHeight - (scrollTop + clientHeight);
      console.log(bottomDistance);
      
      // 当滚动到底部时加载更多数据
      if (bottomDistance < 100 && !this.loading) {
        this.loading = true;
        this.page++;
        this.loadData();
      }
    },
    goback(){
      this.$router.push({path: '/index'})
    },
    loadData(){
      this.axios.get(
        '/api/user/moneylog', {
          params:{
            'p' : this.page
          },
          headers: {
            'token' : localStorage.getItem('token')
          },
        }
      ).then((res)=>{
        if(res.data.code == 1){
          this.record = [...this.record,...res.data.data.data];
          if(res.data.data.last_page == this.p){//最后一页加载完毕
              this.loaded = true;
          }
        }else{
          this.$dialog(res.data.msg);
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog('请先登录');
        return false;
      }
      return true;
    }
  },
        
}
</script>

<style scoped>
.content{
  padding: 50px 15px 80px;
  overflow-y: scroll;
}
.list{
  background: #1E2B5F;
  border-radius: 10px;
  padding: 0 15px;
}
.list .item{
  padding: 15px 0;
  border-bottom: 1px solid #979797;
}
.list .item:last-child{
  border: none;
}
.list .item .left{
  text-align: left;
}
.list .item .left p{
  font-size: 16px;
  padding-bottom: 5px;
}
.list .item .left span{
  font-size: 13px;
  color: #B9B9B9;
}
.list .item > p{
  font-size: 20px;
  color: #27DB9E;
}
@media screen and (min-width: 640px) {
  .list{
    margin: 20px 0;
  }
}
</style>
