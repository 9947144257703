<template>
  <div>
    <div class="contact custom" @click="isShowTg = true">
      <p>联系客服</p>
    </div>
    <div class="custom" v-if="isShowTg" @click="isShowTg = false">
      <h5>客服飞机号</h5>
      <a :href="kefuUrl" target="_blank" class="flex" v-if="kefu">
          <img src= '../assets/images/icon_my_pub.png'/>@{{kefu}}
      </a>
      <a :href="kefu2Url" target="_blank" class="flex" v-if="kefu2">
          <img src= '../assets/images/icon_my_pub.png'/>@{{kefu2}}
      </a>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Custom',
  data() {
    return {  
      isShowTg: false,
      kefu: '',
      kefu2: '',
      kefuUrl: '',
      kefu2Url: '',
    };
  },

  created() {
    this.axios.get(
        '/api/user/getCustom'
      ).then((res)=>{
        if(res.data.code == 1){
          this.kefu = res.data.data.kefu
          this.kefu2 = res.data.data.kefu2
          this.kefuUrl = "https://t.me/"+ this.kefu
          this.kefu2Url = "https://t.me/"+ this.kefu2
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  mounted(){

  },

  methods: {

  }
}
</script>

<style scoped>
.mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明蒙版颜色 */
  z-index: 9999; /* 设置遮罩层的层级 */
}
</style>
