import Vue from 'vue'
import VueRouter from 'vue-router'
import IndexView from '../views/IndexView.vue'
import ChatView from '../views/ChatView.vue'
import PublistView from '../views/PublistView.vue'
import PublishView from '../views/PublishView.vue'
import RechargeView from '../views/RechargeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from '../views/RegisterView.vue'
import ForgetView from '../views/ForgetView.vue'
import WalletView from '../views/WalletView.vue'
import RecordView from '../views/RecordView.vue'
import ActivityView from '../views/ActivityView.vue'
import DetailView from '../views/DetailView.vue'
import ArticleView from '../views/ArticleView.vue'
import MoneyRecordView from '../views/MoneyRecordView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', name: 'index', component: IndexView
  },
  {
    path: '/index', name: 'index', component: IndexView
  },
  {
    path: '/publish', name: 'publish', component: PublishView
  },
  {
    path: '/publist', name: 'publist', component: PublistView
  },
  {
    path: '/detail', name: 'detail', component: DetailView
  },
  {
    path: '/recharge', name: 'recharge', component: RechargeView
  },
  {
    path: '/wallet', name: 'wallet', component: WalletView
  },
  {
    path: '/record', name: 'record', component: RecordView
  },
  {
    path: '/moneyrecord', name: 'moneyrecord', component: MoneyRecordView
  },
  {
    path: '/chat', name: 'chat', component: ChatView
  },
  {
    path: '/login', name: 'login', component: LoginView
  },
  {
    path: '/forget', name: 'forget', component: ForgetView
  },
  {
    path: '/register', name: 'register', component: RegisterView
  },
  {
    path: '/activity', name: 'activity', component: ActivityView
  },
  {
    path: '/article', name: 'article', component: ArticleView
  },
]

const router = new VueRouter({
  mode: 'hash', // require service support
  routes
})

export default router
