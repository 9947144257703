<template>
  <div class="content" v-loading="loading" element-loading-text="请求中" element-loading-background="rgba(0, 0, 0, 0.5)">
    <div class="header flex">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>发布需求</p>
      <div class="button"></div>
    </div>


    <div class="publish_box">
      <div class="item">
        <p>需求信息</p>
        <textarea placeholder="请输入需求信息" v-model="bank" @blur="setAmount()"></textarea>
      </div> 
      <!-- <div class="item">
        <p>支店名（シテンメイ）</p><input type="text" placeholder="请输入支店名" v-model="branch" />
      </div>
      <div class="item">
        <p>引取种类（取引種類）</p>
        <select v-model="type">
          <option value="">请选择引取种类</option>
          <option value="普通">普通</option>
          <option value="当座">当座</option>
        </select>
      </div>
      <div class="item">
        <p>口座番号（口座番号）</p><input type="text" placeholder="请输入口座番号" v-model="csno" />
      </div>
      <div class="item">
        <p>客户姓名（力タ力ナ名）</p><input type="text" placeholder="请输入力タ力ナ名" v-model="receiver" />
      </div>-->
      <div class="item">
        <p>下发金额（引き出し金額）</p><input type="number" placeholder="请输入引き出し金額" v-model="num" />
      </div>
      <!-- <div class="item">
        <p>改名</p><input type="text" placeholder="选填，不改可留空" v-model="extra" />
      </div> -->
    </div>
    
    <div class="rates">
      <h6>汇率说明：</h6>
      <ul>
        <li v-for="(item, index) in percent">
          出金金额：{{item.from}}
          <span v-if="item.to > 50000">及以上，</span>
          <span v-else> - {{item.to}}，</span>
          汇率：{{item.rate}}
        </li>
      </ul>
    </div>
    
    <div class="operate" @click="publish()">
      <el-button type="primary">保存</el-button>
    </div>
    <span style="display: none;">
      <audio id="audio" src="../assets/images/hongbao.mp3" controls="controls"></audio>
    </span>
    
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'PublishView',
  components: {
    Footer
  },
  data() {
      return {
        bank:'',//银行名
        branch:'',//支行名
        type:'',//转账类型
        csno:'',//坐席编号
        receiver:'',//收款人
        num:'',//转账金额
        extra:'',//改名
        audio: '',
        loading: false,
        percent: []
      };
    },

  created() {
  },

  //模板渲染后调用
  mounted() {
    this.audio = document.getElementById("audio");
    this.getPercent();
  },

  // 方法
  methods: {
    //识别转账金额
    setAmount(){
      //根据换行符转化为数组
      var pubInfo = this.bank.split("\n"); 
      var amountStr = '';
      pubInfo.forEach(function(item, index){
        //根据特定字样提取金额一行
        if(item.includes("日元") || item.includes("金額")){
          amountStr = item;
        }
      })

      //去掉非数字的字符
      var amount = amountStr.replace(/\D/g, '');

      this.num = amount;
    },

    //获取
    getPercent(){
      this.axios.get('/api/index/getPercent',).then((res)=>{    
        console.log(res)
        if(res.data.code == 1){
          this.percent = res.data.data
        }
      }).catch((res)=>{
        this.loading = false;
        this.$checkError(res);
      })
    },
    
    //发布
    publish(){
      const token = localStorage.getItem('token')
      if(!token){
        return;
      }

      if(!this.bank){
        this.$dialog('请填写发布信息');
        return false;
      }
      
      if(!this.num || this.num < 5000){
        this.$dialog('引出金額不能小于5000');
        return false;
      }

      this.loading = true;
      this.axios.post(
          '/api/user/exchange', {
            bank: this.bank,//银行名
            branch: this.branch,//支行名
            type: this.type,//转账类型
            csno: this.csno,//坐席编号
            receiver: this.receiver,//收款人
            num: this.num,//转账金额
            extra: this.extra,//改名
          },
          {
            headers: {
              'token' : token
            },
          }
        ).then((res)=>{          
          var that = this
          if(res.data.code == 1){
            //that.playSong(1)
            setTimeout(() => {
              that.$router.push({path:'/publist'})
              this.loading = false;
              this.$dialog(res.data.msg);
            }, 1000);
          }else{
            this.loading = false;
            this.$dialog(res.data.msg);
          }
        }).catch((res)=>{
          this.loading = false;
          this.$checkError(res);
        })
    },

    goback(){
      this.$router.push({path:'/publist'})
    },
    playSong(wi) {
      this.audio.load();
      this.stopSong();
      this.audio.play();
      if (wi == 0) {
        this.audio.pause();
      }
    },
    stopSong() {
      this.audio.pause();
    }
  },
        
}
</script>

<style scoped>
  .content{
    padding: 50px 15px 80px;
    overflow-y: scroll;
  }
  .publish_box{
    background: #1E2B5F;
    border-radius: 10px;
    padding: 10px 10px 0;
  }
  .publish_box .item{
    padding-bottom: 15px;
    text-align: left;
  }
  .publish_box .item p{
    padding-bottom: 5px;
    font-size: 15px;
    color: #fff;
  }
  .publish_box .item textarea {
    width: 100%;
    height: 280px;
    padding: 10px;
    color: #000;
    border-radius: 5px;
    background: #fff;
    margin-top: 5px;
  }
  .publish_box .item input, .publish_box .item select{
    width: 100%;
    height: 40px;
    padding-left: 10px;
    color: #000;
    border-radius: 5px;
    background: #fff;
  }
  .publish_box .item select{
    color: #777;
    padding-left: 6px;
  }
  .publish_box .item select option{
    color: #333;
  }
  .rates{
    width: 100%;
    text-align: left;
    padding-top: 20px;
  }
  .rates h6{
    color: #eee;
    font-size: 16px;
  }
  .rates ul li{
    padding-top: 3px;
    color: #eee;
  }
  
  @media screen and (max-width: 640px) {
    .operate{
      width: 100%;
      padding: 15px;
      position: fixed;
      left: 0;
      bottom: 0;
      background: #0a113d;
    }
    .operate button{
      width: 100%;
      height: 40px;
      border-radius: 40px;
      background: #27DB9E;
    }
  }
  @media screen and (min-width: 640px) {
    .publish_box{
      margin-top: 20px;
    }
    .operate{
      width: 100%;
      margin-top: 50px;
      background: #0a113d;
    }
    .operate button{
      width: 100%;
      height: 50px;
      border-radius: 40px;
      background: #27DB9E;
      font-size: 16px;
    }
  }
</style>
