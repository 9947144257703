<template>
  <div class="content login">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>注册</p>
    </div>

    <div class="login_box flex">
      <img class="img_login" src="../assets/images/img_login.png" />
      <div class="login_form">
        <h1>注册</h1>
        <input class="input_text" type="text" v-model="username" placeholder="请输入用户名"/>
        <input class="input_text" type="password" v-model="password" placeholder="请输入密码"/>
        <input class="input_text" type="password" v-model="password2" placeholder="请输入确认密码"/>
        <input class="input_text" type="text" v-model="mobile" placeholder="请输入飞机号"/>
        <input class="input_text" type="text" v-model="code" placeholder="请输入邀请码，选填"/>
        <input class="input_submit" type="submit" value="注册" @click="register()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'RegisterView',
  components: {
    Footer
  },
  data() {
      return {
        username: "",
        password: "",
        password2: "",
        mobile: "",
        code: '',
        agree: false,
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //注册
    register(){
      if(!this.username ||!this.mobile || !this.password || !this.password2){
        this.$dialog('注册信息不完善');
        return
      }
      this.axios.post(
        '/api/user/register', {
          username: this.username,
          mobile: this.mobile,
          password: this.password,
          password2: this.password2,
          invite_code: this.code,
        }
      ).then((res)=>{
        var that = this
        this.$dialog(res.data.msg);
        if(res.data.code == 1){
          localStorage.setItem('token', res.data.data.userinfo.token)
          setTimeout(function(){
            that.$router.push({path:'/index'})
          }, 1000)
        }else{
          return
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goAuth(){
      this.$router.push({path:'/auth'})
    },

    // 返回上一页
    goback(){
      this.$router.go(-1);
    },
  }
        
}
</script>

<style scoped>
</style>
