<template>
  <div class="content">
    <div class="header flex">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>客服</p>
      <div class="button"></div>
    </div>

    <div class="msg_main">
      <div class="msg_box" id="msg_box">
        <div class="msg_list" ref="msgList">
          <div class="item" :class="item.type==1?'right':'left'" v-for="(item, index) in msgList">
            <img src="../assets/images/avatar.png" />
            <div class="msg">
              <span>{{ item.sender_name }} {{ item.add_time }}</span>
              <div class="msg_cnt">
                <p v-html="item.msg"></p>
              </div>
            </div>
          </div>

        </div>

        <div v-if="msgList.length < 1">暂无消息~</div>
      </div>      

      <div class="send_box">
        <form>
          <textarea v-model="content"></textarea>
          <div class="upload_file">
            <div class="item">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadUrl"
                  :show-file-list="false"
                  :on-success="handleUploadSuccess"
                  :before-upload="beforeUpload">
                  <!-- <img v-if="fileUrl" src="../assets/img/audio.png" class="avatar"> -->
                  <i class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
            </div>
          </div>
          <input type="button" value="发送" @click="sendMsg()"/>
        </form>
      </div>
    </div>

    <div class="img_prev flex" v-if="prev_url" @click="closePrev">
      <img :src="prev_url" />
    </div>

    <!-- <Footer activeIndex="3"></Footer> -->
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'ChatView',
  components: {
    Footer
  },
  data() {
      return {
        uploadUrl:  API_BASE_URL + '/api/common/upload?token='+localStorage.getItem("token"), //图片上传地址
        imgurl: require("@/assets/images/bg_member.png"),
        fileUrl: '',
        fileName: '',
        msg_type: 'text',
        prev_url: '',
        content: '',
        msgList: [],
        timer: ''
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    const token = localStorage.getItem("token");
    if(!token){
      this.$dialog('请先登录');
      setTimeout(() => {
        this.$router.push({path:'/login'})
      }, 1000);
      return false;
    }
    
    this.getMsgDetail();    
    setTimeout(res => {
      this.refreshMsgList();
    }, 1000)

    // 滚动到底部
    // var that = this;
    // setTimeout(function(){
    //   that.scrollToBottom();
    // }, 500)
    
  },

  // 方法
  methods: {
    openFile(resource){
      window.open(resource, '_blank');
    },

    //预览
    prevImg(url){
      this.prev_url = url
    },
    closePrev(){
      this.prev_url = ''
    },

    // 消息列表
    getMsgDetail(){
      this.axios.get(
        '/api/user/getMessages',
        {
          headers: {
            'token' : localStorage.getItem('token')
          },
        }
      ).then((res)=>{
        if(res.data.code == 1){
          if(this.msgList.length == res.data.data.msg_count){
            return;
          }
          this.msgList = res.data.data.data;
          var that = this;
          setTimeout(() => {
            that.scrollToBottom();
          }, 1000);
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //发送消息
    sendMsg(){
      if(!this.content){
        this.$dialog('消息内容不能为空'); return false;
      }
      this.axios.post(
        '/api/user/sendMessage', {
          msg: this.content,
        }, 
        {
          headers: {
            'token' : localStorage.getItem('token')
          },
        }).then((res)=>{
        if(res.data.code == 1){
          this.content = '';
          this.getMsgDetail();
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //刷新对话信息
    refreshMsgList(){
      var that = this;
      this.timer = setInterval(function(){
        that.getMsgDetail();
      }, 5000)
    },

    scrollToBottom() {
      // 在下一个 DOM 更新周期中执行回调函数
      const elementHeight = this.$refs.msgList.scrollHeight;
      this.$refs.msgList.scrollTop = elementHeight;
    },

    //附件
    handleUploadSuccess(res, file) {
      if(res.code == 1){
        this.fileUrl = res.data.fullurl;
        this.content = '[img src=|'+res.data.fullurl+'|]';
        this.showConfirm();
      }
      
    },
    beforeUpload(file) {
      console.log(file)
      this.fileName = file.name
      this.msg_type = file.type.split('/')[0];
      console.log(this.msg_type)

      const isLt20M = file.size / 1024 / 1024 < 20;
      if (!isLt20M) {
        this.$dialog('附件大小不能超过20MB'); return false;
      }
      return isLt20M;
    },

    showConfirm(){
      this.sendMsg();
    },

    goback(){
      this.$router.go(-1);
    },
  },

  beforeDestroy() {
    // 在销毁前执行一些逻辑:清除定时器
    clearInterval(this.timer);
  },
        
}
</script>

<style>
  .content{
    height: 100vh;
    padding: 50px 15px 0;
  }
  .el-message-box{
    max-width: 90%;
  }
  .el-message-box .el-message-box__title{
    text-align: center;
  }

  .msg_main {
    width: 100%;
    height: 100%; 
    overflow: hidden;
  }
  .msg_box{
    height: 75%; 
    overflow: hidden; 
    scroll-behavior: smooth;
    background: #fff;
    border: 1px solid #ccc; 
    border-radius: 5px;
    padding: 10px;
  }
  .msg_box .msg_list{
    width: 100%;
    height: 100%;
    overflow-y: auto;
  }
  .msg_box .item{
    width:100%; 
    margin-bottom: 10px; 
    overflow: hidden;
  }
  .msg_box .item > img{
    width: 30px; 
    height: 30px; 
    border-radius: 50%; 
    position: relative; 
    top: 5px;
  }
  .msg_box .right .msg .msg_cnt {
    padding-top: 3px;
    display: flex;
    justify-content: flex-end;
  }
  .msg_box .left .msg .msg_cnt {
    padding-top: 3px;
    display: flex;
    justify-content: flex-start;
  }
  .msg_box .item .msg .msg_cnt > img{
    width: 100%;
  }
  .msg_box .item .msg video{
    width: 100%;
  }
  .msg_box .item .msg audio{
    width: 100%;
  }
  .msg_box .item .msg object > img{
    width: 40px;
  }
  .msg_box .left > img {
    float: left
  }
  .msg_box .left .msg {
    float: left; 
    text-align: left; 
    padding-left: 10px;
  }
  .msg_box .right > img{
    float: right
  }
  .msg_cnt img{
    max-width: 100%;
  }
  .msg_box .right .msg {
    float: right; 
    text-align: right; 
    padding-right: 10px;
  }
  .msg_box .right .msg p{
    text-align: right; 
  }
  .msg_box .msg {
    max-width: 70%;
    min-height: 40px;
  }
  .msg_box .msg span{
    color: #999; 
    font-size: 12px;
  }
  .msg_box .msg p{
    padding: 5px 10px; 
    background: #18bc9c; 
    border-radius: 5px; 
    line-height: 25px; color: #fff; 
    text-align: justify;
  }
  .msg_box .left .msg p{
    background: #eee; 
    color: #333;
  }
  /* //滚动条样式 */
  ::-webkit-scrollbar {
    width: 0px;
  }
  .send_box {
    width: 100%;
    height: 23%; 
    margin-top: 2%; 
    position: relative; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    overflow: hidden;
  }
  .send_box form{
    height: 100%;
  }
  .send_box textarea{
    width: 100%; 
    height: 100%; 
    outline: none; 
    line-height: 25px; 
    resize: none; 
    border: none; 
    padding: 5px 10px;
    color: #333;
    font-size: 14px;
  }
  .send_box input {
    position: absolute; 
    right: 10px; 
    bottom: 10px; 
    background: #18bc9c; 
    color: #fff; 
    border: none; 
    width: 70px; 
    height: 30px; 
    border-radius: 3px;
  }
  .upload_file{
    position: absolute;
    right: 90px;
    bottom: 10px;
    width: 30px;
    height: 30px;
    background: #18bc9c;
    border-radius: 50%;
    line-height: 30px;
  }
  .upload_file >>> .el-upload .avatar-uploader-icon{
    color: #fff;
    font-size: 20px;
    line-height: 30px;
  }

  .img_prev{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgb(0,0,0,0.7);
    padding: 0 30px;
  }
  .img_prev img{
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  @media screen and (min-width: 640px) {
    .msg_main{
      padding: 20px 0;
    }
  }
</style>
