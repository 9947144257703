<template>
  <div class="content">
    <div class="header">
      <p>热门活动</p>
    </div>
    
    <div class="list">
      <div class="item" v-for="(item, index) in record" @click="article(item.id)">
        <img :src="item.cover" />
        <div class="title flex">
          <p>{{ item.title }}</p> <b></b>
        </div>
      </div>
    </div>

    <div class="no_date" v-if="record.length == 0">暂无数据~</div>

    <Footer activeIndex="3"></Footer>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'PublistView',
  components: {
    Footer
  },
  data() {
      return {
        record: [],
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    this.axios.get(
        '/api/index/articlelist'
      ).then((res)=>{
        if(res.data.code == 1){
          this.record = res.data.data
        }else{
          this.$dialog(res.data.msg);
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  // 方法
  methods: {
    //活动详情
    article(id){
      this.$router.push({path: '/article?id='+id});
    },

    goback(){
      this.$router.go(-1);
    }
  },
        
}
</script>

<style scoped>
.content{
  padding: 50px 15px 80px;
}
.list .item{
  background: #1E2B5F;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;
}
.list .item:last-child{
  border: none;
}
.list .item img{
  width: 100%;
  display: block;
}
.list .item .title{
  padding: 8px 10px;
  background: #fff;
  color: #1E2B5F;
}
.list .item .title p{
  font-size: 16px;
  padding-bottom: 5px;
  color: #1E2B5F;
}
.list .item .title b{
  display: none;
  width: 5px;
  height: 5px;
  border-radius: 10px;
  background: #f00;
}
@media screen and (min-width: 640px) {
  .list{
    margin-top: 20px;
  }
  .list .item{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
  }
  .list .item img{
    width: 40%;
  }
  .list .item .title{
    padding: 0 0 0 20px;
    background: none;
  }
  .list .item .title p{
    color: #fff;
  }
}
</style>
