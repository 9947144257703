<template>
  <div class="content article">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>活动详情</p>
    </div>
    
    <div class="title">
      <h2>{{ title }}</h2>
      <p>{{ addtime }}</p>
    </div>

    <div class="detail" v-html="content">
    </div>

  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'ArticleView',
  components: {
    Footer
  },
  data() {
      return {
        title: '-',
        content: '-',
        addtime: '-'
      };
    },

  created() {
    this.axios.get(
        '/api/index/articledetail?id='+this.$route.query.id,
      ).then((res)=>{
        if(res.data.code == 1){
          this.title = res.data.data.title
          this.content = res.data.data.content
          this.addtime = res.data.data.addtime
        }else{
          this.$dialog(res.data.msg);
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
  },

  //模板渲染后调用
  mounted() {
    
  },

  // 方法
  methods: {
    goback(){
      this.$router.go(-1);
    }
  },
        
}
</script>

<style >
.article{
  padding: 50px 15px 0;
}
.article .title{
  text-align: left;
  padding: 20px 0;
  border-bottom: 1px solid #979797;
}
.article .title h2{
  font-size: 18px;
  font-weight: normal;
}
.article .title p{
  color: #8B8B8B;
  padding-top: 10px;
}
.article .detail{
  width: 100%;
  overflow: hidden;
  margin-top: 15px;
  text-align: justify;
}
.article .detail img{
  width: 100% !important;
}
.article .detail p{
  text-align: justify;
  line-height: 25px;
  margin-top: 10px;
}
</style>
