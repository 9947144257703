<template>
  <div class="content" v-if="!showDetail">
    <div class="header">
      <div class="left"></div>
      <p>我的发布</p>
      <div class="button btn_pub" @click="publish()">发布需求</div>
    </div>

    <div class="search">
      <input type="text" v-model="searchVal" placeholder="请输入订单号或银行" />
      <div class="btn_search"><img src="../assets/images/icon_search.png" /></div>
    </div>
    

    <div class="list">
      <div class="item" v-for="(item, index) in filteredItems">
        <div class="order flex">
          <p>订单号：{{ item.order_sn }}</p><span :class="item.status == 1 ? 'complete' : ''">{{ item.status_txt }}</span>
        </div>
        <div class="my_item flex info">
          <p v-if="item.payee">银行名</p><p v-else>需求信息</p>
          <div v-html="item.bank_name"></div>
        </div>
        <div class="my_item flex" v-if="item.payee"><p>客户姓名</p><input :value="item.payee" readonly/></div> 
        <div class="my_item flex" v-if="item.subbranch"><p>支店名</p><input :value="item.subbranch" readonly/></div> 
        <div class="my_item flex" v-if="item.transfer_type"><p>引取种类</p><input :value="item.transfer_type" readonly/></div> 
        <div class="my_item flex" v-if="item.cs_no"><p>口座番号</p><input :value="item.cs_no" readonly/></div> 
        <div class="my_item flex"><p>引出金額</p><input :value="item.transfer_amount" readonly/></div> 
        <div class="my_item flex"><p>发布时间</p><input :value="item.createtime" readonly/></div> 
        <div class="operates flex">
          <p>合计：<b>{{ item.amount }}<span>（USDT）</span></b></p>
          <div class="btns">
            <button v-if="item.status == 0" @click="confirmDelete(item.id)" class="btn_del">取消</button>
            <button @click="showDetail=true;pub=item;">详情</button>
          </div>
        </div>
      </div>
    </div>

    <div class="no_date" v-if="publist.length < 1">暂无数据~</div>

    <Footer activeIndex="1"></Footer>

  </div>
  <div class="content" v-else>
    <div class="header">
      <div class="left" @click="showDetail=false;pub={}"><img src="../assets/images/right_white.png" /></div>
      <p>详情</p>
    </div>
    <DetailView v-bind="pub">
    </DetailView>
  </div>
</template>
<script>
import Footer from '@/components/Footer.vue'
import DetailView from './DetailView.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'PublistView',
  components: {
    Footer,
    DetailView
  },
  data() {
      return {
        publist: [],
        showDetail: false,
        searchVal: '',
        pub: {},
      };
    },
  computed: {
    filteredItems() {
      return this.publist.filter(item => {
        return item.order_sn.indexOf(this.searchVal) > -1 || item.bank_name.indexOf(this.searchVal) > -1;
      });
    }
  },

  created() {
    if(this.checkLogin()){
      this.getPubList(1)
    }
  },

  //模板渲染后调用
  mounted() {
  },

  // 方法
  methods: {
    //获取列表
    getPubList(p){
      this.axios.get(
        '/api/user/publist', {
          params: {
            'p':p,
          },
          headers: {
            'token' : localStorage.getItem('token')
          },
      }).then((res)=>{
        if(res.data.code == 1){
          this.publist = res.data.data
        }else{
          this.$dialog(res.data.msg)
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //确认取消
    confirmDelete(id){
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delOrder(id);
        }).catch(() => {
          this.$dialog('已取消');          
        });
    },

    //取消订单
    delOrder(id){
      console.log(id);
      this.axios.post(
        '/api/user/delOrder', {'id': id}, {
          headers: {
            'token' : localStorage.getItem('token')
          },
      }).then((res)=>{
        if(res.data.code == 1){
          this.$dialog('取消成功')
          this.getPubList(1);
        }else{
          this.$dialog(res.data.msg)
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    //校验登录
    checkLogin(){
      const token = localStorage.getItem("token");
      if(!token){
        this.$dialog('请先登录');
        setTimeout(() => {
          this.$router.push({path:'/login'})
        }, 1000);
        return false;
      }
      return true;
    },

    //发布需求
    publish(){
      this.$router.push({path:'/publish'})
    },

    detail(){
      this.$router.push({path:'/detail'})
    }
  },
        
}
</script>

<style scoped>
.search{
  padding: 0 15px;
  margin: 15px 0 5px;
  position: relative;
}
.search input{
  width: 100%;
  height: 40px;
  padding-left: 10px;
  background: #1E2B5F;
  border: none;
  color: #fff;
}
.search .btn_search{
  position: absolute;
  width: 40px;
  height: 40px;
  right: 15px;
  top: 0;
  padding: 10px;
}
.search .btn_search img{
  height: 20px;
}
.content{
  padding: 50px 0;
  overflow-y: scroll;
}
.header .btn_pub{
  color: #fff;
  border-radius: 5px;
  background: #27db9e;
  padding: 0 5px;
}
.list{
  padding: 15px;
}
.list .item{
  background: #1E2B5F;
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 15px;
}
.list .item .order {
  padding-bottom: 10px;
  border-bottom: 1px solid #979797;
  font-size: 16px;
  margin-bottom: 5px;
}
.list .item .order span{
  display: block;
  padding: 5px 10px;
  font-size: 12px;
  color: #fff;
  background: #E577AE;
  border-radius: 3px;
}
.list .item .order span.complete{
  background: #27db9e;
}
.list .item .my_item {
  line-height: 30px;
}
.list .item .my_item.info {
  align-items: flex-start;
}
.list .item .my_item p{
  width: 25%;
  color: #C3C1C1;
  text-align: left;
 }
.list .item .my_item input, .list .item .my_item div{
  width: 75%;
  border: none;
  background: none;
  color: #fff;
  padding-left: 10px;
 }
 .list .item .my_item div{
  text-align: left
 }
.list .item .operates{
  padding-top: 10px;
}
.list .item .operates p{
  font-size: 15px;
}
.list .item .operates p b{
  font-size: 18px;
  color: #27db9e;
}
.list .item .operates p b span{
  font-size: 14px;
  color: #27db9e;
}
.list .item .operates button{
  border: 1px solid #fff;
  padding: 3px 15px;
  background: none;
  color: #fff;
  border-radius: 30px;
}
.list .item .operates button.btn_del{
  color: #E577AE;
  border: 1px solid #E577AE;
  margin-right: 10px;
}
@media screen and (min-width: 640px) {
  .list{
    padding: 15px 0;
  }
  .search{
    padding: 0 0;
  }
}
</style>
