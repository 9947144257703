<template>
  <div class="content login">
    <div class="header">
      <div class="left" @click="goback()"><img src="../assets/images/right_white.png" /></div>
      <p>找回密码</p>
    </div>

    <div class="login_box flex">
      <img class="img_login" src="../assets/images/img_login.png" />
      <div class="login_form">
        <h1>找回密码</h1>
        <input class="input_text" type="text" v-model="username" placeholder="请输入用户名"/>
        <input class="input_text" type="text" v-model="mobile" placeholder="请输入飞机号"/>
        <input class="input_text" type="password" v-model="newpass" placeholder="请输入新密码"/>
        <input class="input_text" type="password" v-model="newpass2" placeholder="请确认新密码"/>
        <input class="input_submit" type="submit" value="提交" @click="forgetPass()"/>
      </div>
    </div>
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue'
import { API_BASE_URL } from '../constant';
export default {
  name: 'ForgetView',
  components: {
    Footer
  },
  data() {
      return {
        username: "",
        mobile: "",
        newpass: "",
        newpass2: "",
      };
    },

  created() {

  },

  //模板渲染后调用
  mounted() {
    
  },

  // 方法
  methods: {
    //登录
    forgetPass(){
      if(!this.username || !this.mobile || !this.newpass){
        this.$dialog('账号密码不能为空');
        return false
      }

      if(this.newpass != this.newpass2){
        this.$dialog('两次新密码不一致');
        return false
      }

      this.axios.post( '/api/user/resetpassword', {
        username: this.username,
        mobile: this.mobile,
        newpassword: this.newpass,
        newpassword2: this.newpass2,
      }).then((res)=>{
        var that = this
        
        if(res.data.code == 1){
          this.$dialog('密码找回成功，请登录');
          setTimeout(function(){
            that.$router.push({path:'/login'})
          }, 1000)
        }else{
          this.$dialog(res.data.msg);
          return false;
        }
      }).catch((res)=>{
        this.$checkError(res);
      })
    },

    goback(){
      this.$router.go(-1);
    },

  }
        
}
</script>

<style scoped>
</style>
